<template>
  <th-page-wrapper
    class="discount-edit"
    :title="$t('common.resource.discount.singular')"
    color="var(--secondary-color)"
    align-title="center"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
    @close-requested="$router.push({ name: 'discounts-list' })"
  >
    <discount-form ref="form" />
  </th-page-wrapper>
</template>

<script>
import DiscountForm from './components/form.vue'

export default {
  components: {
    DiscountForm
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'products:discounts',
        returnedPath: { name: 'discounts-list' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 20px;
}
</style>
