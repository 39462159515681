<template>
  <div>
    <el-radio-group v-model="cartBehavior" size="large">
      <el-radio-button label="per_item">
        {{
          $t(
            'pages.discounts.edit.form.properties.cart_behaviors.values.per_item'
          )
        }}
      </el-radio-button>
      <el-radio-button label="per_cart">
        {{
          $t(
            'pages.discounts.edit.form.properties.cart_behaviors.values.per_cart'
          )
        }}
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  computed: {
    cartBehavior: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>

<style scoped></style>
