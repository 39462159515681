<template>
  <div class="form-wrapper">
    <div class="edit-form-wrapper">
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
              prop="name"
              :label="$t('pages.discounts.edit.form.properties.name.label')"
            >
              <el-input
                id="name"
                v-model="form.name"
                :placeholder="
                  $t('pages.discounts.edit.form.properties.name.placeholder')
                "
              />
            </el-form-item>

            <el-form-item prop="type" required>
              <label
                id="type"
                class="text-gray-700"
                style="line-height: 50px"
                v-text="$t('pages.discounts.edit.form.properties.type.label')"
              />
              <div>
                <el-radio-group
                  v-model="form.type"
                  aria-labelledby="type"
                  size="large"
                >
                  <el-radio-button label="percentage">
                    {{
                      $t(
                        'pages.discounts.edit.form.properties.type.values.percent'
                      )
                    }}
                  </el-radio-button>
                  <el-radio-button label="value">
                    {{
                      $t(
                        'pages.discounts.edit.form.properties.type.values.value'
                      )
                    }}
                  </el-radio-button>
                </el-radio-group>
              </div>
            </el-form-item>

            <el-form-item
              prop="locations"
              :label="$t('components.available_in.label')"
            >
              <available-in
                :model-value="form.locations"
                :resources="resources"
                @update:modelValue="handleAvailableInInput"
              />
            </el-form-item>

            <el-form-item
              v-if="showCurrencySelector"
              :label="$t('pages.discounts.edit.form.properties.currency.label')"
              prop="currency"
            >
              <th-currency-select
                id="currency"
                v-model="form.currency"
                :allowed-currencies="currencies"
              />
            </el-form-item>
            <el-form-item
              v-if="isPercentage"
              prop="rate"
              width="20px"
              :label="$t('pages.discounts.edit.form.properties.rate.label')"
            >
              <th-number-input
                v-model="form.rate"
                class="w-48"
                clearable
                clearable-is-left
                percent
                :upper-limit="1.0"
                :precision="2"
                :locale="$i18n.locale"
              />
            </el-form-item>

            <el-form-item
              v-else
              prop="value"
              width="20px"
              :label="$t('pages.discounts.edit.form.properties.value.label')"
            >
              <th-currency-input
                v-model="form.value"
                class="w-48"
                :currency="form.currency"
                clearable-is-left
              />
            </el-form-item>
            <el-form-item
              v-if="form.type !== 'value'"
              prop="group"
              required
              :label="$t('pages.discounts.edit.form.properties.group.label')"
            >
              <div>
                <el-radio-group v-model="form.group" size="large">
                  <el-radio-button label="item">
                    {{
                      $t(
                        'pages.discounts.edit.form.properties.group.values.item'
                      )
                    }}
                  </el-radio-button>
                  <el-radio-button label="cart">
                    {{
                      $t(
                        'pages.discounts.edit.form.properties.group.values.cart'
                      )
                    }}
                  </el-radio-button>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item
              v-if="form.type === 'value'"
              prop="behaviors.cart"
              required
              :label="
                $t('pages.discounts.edit.form.properties.behaviors.cart.label')
              "
            >
              <cart-behavior v-model="form.behaviors.cart" />
            </el-form-item>
            <el-form-item
              v-if="shouldDisplayAutomaticToggle"
              :label="
                $t(
                  'pages.discounts.edit.form.properties.behaviors.automatic.label'
                )
              "
              prop="behaviors.automatic"
            >
              <el-switch
                id="behaviors.automatic"
                v-model="form.behaviors.automatic"
                active-color="#50e3c2"
              />
            </el-form-item>

            <el-form-item
              :label="
                $t(
                  'pages.discounts.edit.form.properties.group_on_receipt.label'
                )
              "
              prop="group_on_receipt"
            >
              <el-switch
                id="group_on_receipt"
                v-model="form.group_on_receipt"
                active-color="#50e3c2"
              />
            </el-form-item>

            <h4
              class="text-gray-600"
              v-text="
                $t('pages.discounts.edit.form.properties.constraints.label')
              "
            />

            <el-form-item
              :label="$t('common.discounts.constraints.taxes')"
              prop="constraints.taxes"
            >
              <th-resource-select
                v-model="taxesConstraints"
                class="w-full"
                resource="taxes"
                :computed-fields="['name', 'fa_account_number']"
                :resource-query="{ query: { deleted: false } }"
                multiple
              />
            </el-form-item>

            <el-form-item
              :label="$t('common.discounts.constraints.product_groups')"
              prop="constraints.product_groups"
            >
              <th-resource-select
                v-model="productGroupsConstraints"
                class="w-full"
                resource="productGroups"
                :computed-fields="['product_group_id', 'name']"
                :resource-query="{ query: { deleted: false } }"
                multiple
              />
            </el-form-item>

            <el-form-item
              :label="$t('common.discounts.constraints.products')"
              prop="constraints.products"
            >
              <products-select
                v-model="form.constraints.products"
                :resources="resources"
                :extend-query="{ sellable: true }"
              />
            </el-form-item>

            <el-form-item :label="$t('common.discounts.constraints.time')">
              <time-constraints
                v-if="form.constraints"
                ref="constraints"
                v-model="form.constraints"
                @update:modelValue="updateConstraints"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import typeOf from 'just-typeof'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import flush from 'just-flush'
import safeSet from 'just-safe-set'
import { mapGetters } from 'vuex'
import { isEmptyObject, isEmptyArray } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import TimeConstraints from '@/components/tillhub/time-constraints'
import CartBehavior from './behaviors/cart'
import { getConstraints } from '@/utils/constraints'
import AvailableIn from '@/components/available-in-legacy'
import ProductsSelect from '@/containers/multi-items-select/products'

const extendedConstraints = (constraints) => ({
  taxes: null,
  product_groups: null,
  products: null,
  ...getConstraints(constraints)
})

function genInitialData({ currency = 'EUR' } = {}) {
  return {
    name: null,
    rate: null,
    value: null,
    type: 'value',
    group: 'cart',
    behaviors: {
      cart: 'per_item',
      automatic: false
    },
    constraints: extendedConstraints(),
    currency,
    locations: null,
    group_on_receipt: false
  }
}

function cleanProductsConstraints(arr) {
  if (!Array.isArray(arr)) return null
  return typeOf(arr[0]) === 'object' ? arr.map((p) => p.id) : arr
}

export default {
  components: {
    TimeConstraints,
    CartBehavior,
    AvailableIn,
    ProductsSelect
  },
  data() {
    return {
      loading: false,
      form: genInitialData(),
      payload: {},
      resources: {}
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation',
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      currencies: 'Config/getAvailableCurrencies'
    }),
    isNew() {
      return !this.$route.params.id
    },
    isPercentage() {
      return this.form && this.form.type === 'percentage'
    },
    taxesConstraints: {
      get() {
        return this.form.constraints.taxes || null
      },
      set(value) {
        const _value = Array.isArray(value) && value.length ? value : null
        this.form.constraints.taxes = _value
      }
    },
    productGroupsConstraints: {
      get() {
        return this.form.constraints.product_groups || null
      },
      set(value) {
        const _value = Array.isArray(value) && value.length ? value : null
        this.form.constraints.product_groups = _value
      }
    },
    rules() {
      return {
        name: [
          {
            required: true,
            message: this.$t('pages.discounts.edit.form.rules.name.required'),
            trigger: 'blur'
          }
        ],
        rate: [
          {
            required: this.form.type === 'percentage',
            message: this.$t('pages.discounts.edit.form.rules.rate.required'),
            trigger: 'blur'
          }
        ],
        value: [
          {
            required: this.form.type === 'value',
            message: this.$t('pages.discounts.edit.form.rules.value.required'),
            trigger: 'blur'
          }
        ],
        'behaviors.cart': [
          {
            required: true,
            message: this.$t(
              'pages.discounts.edit.form.properties.behaviors.cart.required'
            ),
            trigger: 'blur'
          }
        ]
      }
    },
    shouldDisplayAutomaticToggle() {
      return this.form.type !== 'value'
    },
    showCurrencySelector() {
      return this.form.type === 'value' && this.currencies.length > 1
    }
  },
  mounted() {
    this.fetchResources()
    if (!this.isNew) this.fetch(this.$route.params.id)
    if (this.isNew && this.defaultCurrency)
      this.form.currency = this.defaultCurrency
    this.$log.debug('discounts-edit: mounted')
  },
  methods: {
    async fetch(id) {
      const errorMessage = this.$t(
        'pages.discounts.edit.form.errors.fetch.code_XXX.content'
      )
      try {
        const inst = th.discounts()
        this.loading = true
        this.$log.debug('discounts-edit: will fetch resource')
        const { data = {} } = await inst.get(id)

        this.$log.debug('discounts-edit: have fetched resource')

        if (data.id) {
          this.handleItem(data)
          this.$log.debug('discounts-edit: have handled form data after fetch')
        }

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      }
    },
    submitForm() {
      const warningMessage = this.$t(
        'pages.discounts.edit.form.warnings.invalid_inputs.contents'
      )
      this.validate('form', async (valid) => {
        this.validate('constraints', async (validConstraints) => {
          if (!valid || !validConstraints) {
            return this.$message({
              type: 'warning',
              message: warningMessage
            })
          }

          const isAmountValid = await this.validateAmount()
          if (!isAmountValid) return

          const shouldWarnEmptyConstraints =
            safeGet(this.form, 'behaviors.automatic') &&
            !flush([
              safeGet(this.form, 'constraints.product_groups'),
              safeGet(this.form, 'constraints.taxes'),
              safeGet(this.form, 'constraints.products')
            ]).length

          if (shouldWarnEmptyConstraints) {
            try {
              await this.$confirm(
                this.$t(
                  'pages.discounts.edit.form.warnings.automatic_apply.empty_constraints'
                ),
                this.$t('common.titles.warning'),
                {
                  confirmButtonText: this.$t('common.interactions.buttons.ok'),
                  cancelButtonText: this.$t(
                    'common.interactions.buttons.cancel'
                  ),
                  type: 'warning'
                }
              )
            } catch (err) {
              // no-op
              return
            }
          }

          this.loading = true
          const payload = this.makeHandleablediscountsBody(this.form)

          if (this.isNew) return this.create(payload)
          this.alter(this.payload.id, payload)
        })
      })
    },
    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    async create(payload) {
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.discount.singular')
      })
      try {
        const inst = th.discounts()

        this.$log.debug('discounts-edit: will create resource')

        const { data = {} } = await inst.create(payload)

        this.$log.debug('discounts-edit: have created resource')

        this.loading = false
        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.discount.singular')
            })
          })

          this.handleItem(data)

          if (this.navigationAfterCreation === 'edit') {
            this.$router.push({
              name: 'discount-edit',
              params: { id: data.id }
            })
          } else {
            this.$router.push({ name: 'discounts-list' })
          }
        }
      } catch (err) {
        this.loading = false
        this.$logException(err, { message: errorMessage })
      }
    },
    async alter(id, payload) {
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.discount.singular')
      })
      try {
        const inst = th.discounts()
        const { data = {} } = await inst.put(id, payload)

        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.discount.singular')
            })
          })

          this.handleItem(data)
        }

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, { message: errorMessage })
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.payload.name || this.payload.id
      )
      if (confirm) this.deleteDiscount()
    },
    async deleteDiscount() {
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.discount.singular')
      })
      try {
        const inst = th.discounts()
        await inst.delete(this.payload.id)

        this.resetForm()
        this.$router.push({ name: 'discounts-list' })

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, { message: errorMessage })
      }
    },
    makeHandleablediscountsBody(form) {
      const result = {}

      // replace empty objects, emtpy arrays and empty strings with null
      Object.keys(form).forEach((key) => {
        const isEmpty =
          isEmptyObject(form[key]) ||
          isEmptyArray(form[key]) ||
          isEmptyString(form[key])
        result[key] = isEmpty && key !== 'locations' ? null : form[key]
      })

      const products = safeGet(result, 'constraints.products')
      safeSet(
        result,
        'constraints.products',
        cleanProductsConstraints(products)
      )

      return result
    },
    handleItem(item) {
      this.payload = { ...item }

      const cleanedPayload = pick(item, Object.keys(this.form))

      this.form = {
        ...this.form,
        ...cleanedPayload,
        behaviors: { ...cleanedPayload.behaviors },
        constraints: extendedConstraints(cleanedPayload.constraints),
        currency: cleanedPayload.currency || this.defaultCurrency || 'EUR'
      }

      this.$log.debug('form', this.form)
    },
    resetForm() {
      this.$log.debug('discounts-edit: will reset form')

      if (this.isNew) {
        this.form = genInitialData({ currency: this.defaultCurrency })
      } else {
        this.handleItem(this.payload)
      }
    },
    updateConstraints(val) {
      this.form.constraints = val
    },
    async validateAmount() {
      if (this.form.type === 'percentage' && this.form.rate === 1) {
        try {
          await this.$confirm(
            this.$t('pages.discounts.edit.messages.percentage.confirmation'),
            this.$t('pages.discounts.edit.messages.percentage.attention'),
            {
              confirmButtonText: this.$t('common.interactions.buttons.yes'),
              cancelButtonText: this.$t('common.interactions.buttons.no')
            }
          )
          return true
        } catch (err) {
          return false
        }
      } else if (this.form.type === 'percentage' && this.form.rate > 1) {
        const errorMessage = this.$t(
          'pages.discounts.edit.form.errors.percentage.exceeds'
        )
        this.$logException(new Error(errorMessage), { message: errorMessage })
        return false
      }
      return true
    },
    validateRateOrValue(rule, value, callback) {
      if (this.form.rate || this.form.vale) {
        callback()
      } else {
        callback(new Error())
      }
    },
    async fetchResources() {
      try {
        const {
          branchesV1 = [],
          branchGroups = [],
          productGroups = [],
          taxes = [],
          accounts = []
        } = await this.$resourceFetch(
          'branchesV1',
          'branchGroups',
          'productGroups',
          'taxes',
          {
            resource: 'accounts',
            handler: () =>
              th.accounts().getAll({
                query: { type: 'revenue', deleted: false, active: true }
              })
          }
        )
        this.resources = {
          branches: branchesV1,
          branchGroups,
          productGroups,
          taxes,
          accounts
        }
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    handleAvailableInInput({ locations }) {
      this.form.locations = locations
    }
  }
}
</script>

<style scoped>
.form-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
}

.edit-form-wrapper {
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: 80%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.el-form {
  width: 70%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
}

.form-header {
  font-weight: bold;
  margin-top: 20px;
}

.el-form-item {
  margin: 10px 0 20px 0;
}

.el-form-item :deep(.el-form-item__label) {
  padding: 0;
  font-weight: 600;
}

.zero-margin {
  margin: 0px;
}
</style>
